<template>
    <div class="navbarmobileFix">
        <!-- <hr /> -->
        <nav class="three-nav d-none d-lg-block">
            <!-- <div v-on:click="seen = !seen" id="app">
                <li role="menuitem" class="navbar-nav-list hidden-md hidden-lg dropdown xs-design open-side-menu">
                    <a role="link" data-toggle="dropdown" href="#" tabindex="2" class="dropdown-toggle"
                        aria-expanded="false"><i id="ember883" class="gru-icon menu material-icons ember-view">Feature
                        </i></a>
                    <ul class="dropdown-menu">
                        <li class="navbar-nav-list close-menu open">
                            <button data-toggle="dropdown" tabindex="2" class="btn dropdown-toggle"
                                aria-expanded="true"><i id="ember884"
                                    class="gru-icon close material-icons ember-view">close
                                </i></button>
                        </li>
                    </ul>
                </li>
            </div> -->
            <ul class="three-nav navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
                <li class="three-nav" @click="joinPatient()">
                    <div>
                        <img src="~@/assets/images/patientgroup.png" style="width: 40px;">
                    </div>
                </li>
                <li class="three-nav" @click="getCase()">
                    <div>
                        <img src="~@/assets/images/one-one.png" style="width: 40px;">
                    </div>
                </li>
                <li class="three-nav" @click="offerConsultation()">
                    <div>
                        <img src="~@/assets/images/meet-icon.png" style="width: 40px;">
                    </div>
                </li>
                <li class="three-nav" @click="accessClinical()">
                    <div>
                        <img src="~@/assets/img/medical-knowledge.png" style="width: 40px;">
                    </div>
                </li>
                <!-- <li class="three-nav" @click="postConsultation()">
                <div>
                    <img src="~@/assets/images/group.png" style="width: 40px;">
                </div>
            </li> -->
            </ul>
        </nav>

        <section class="banner-text receive-banner">
            <div class="container text-center text-align">
                <!-- FOR DOCTOR  -->
                <div class="banner-sub receiveConsultationTextHeading d-none d-lg-block ">
                    <h1>Receive consultation requests</h1>
                </div>
                <!-- FOR MOBILE  -->
                <div class="banner-sub receiveConsultationTextHeading d-block d-lg-none">
                    <h1 class="mb-0">Receive</h1>
                    <h1>consultation requests</h1>
                </div>
                <div class="row">
                    <!-- FOR DOCTOR  -->
                    <div class="col-12 d-none d-lg-block banner-sub-content text-align text-center">
                        <h3>We charge no referral fee. </h3>

                        <h3 class="mt-4 mb-0">Your clinic and practice contact details are available for our members</h3>
                        <h3>to view and contact you directly.</h3>

                        <h3 class="mt-4 mb-0">Willing to offer home consultation to requests raised by our members?</h3>
                        <h3 class="mb-0">For home consultations, we collect consultation charges on your behalf</h3>
                        <h3>and charge you a nominal payment processing fee.</h3>
                    </div>
                    <!-- FOR MOBILE  -->
                    <div class="col-12 d-block d-lg-none banner-sub-content text-align text-center receiveConsultationText">
                        <h3 class="">We charge no referral fee. </h3>

                        <h3 class="mt-4 mb-0">Your clinic and practice</h3>
                        <h3 class="mb-0">contact details are available</h3>
                        <h3 class="mb-0">for our members to view and</h3>
                        <h3>contact you directly.</h3>


                        <h3 class="mt-4 mb-0">Willing to offer home</h3>
                        <h3 class="mb-0">consultation to requests </h3>
                        <h3 class="mb-0">raised by our members?</h3>

                        <h3 class="mb-0">For home consultations, we</h3>
                        <h3 class="mb-0">collect consultation charges</h3>
                        <h3 class="mb-0">on your behalf and charge you</h3>
                        <h3 class="mb-0">a nominal payment processing</h3>
                        <h3> fee.</h3>
                    </div>
                </div>
            </div>
        </section>
        <!-- <div class="container rx-wrapper pl-0 pr-0" style="background: #ffffff;height: 100vh;" id="presign"> -->

        <section>
            <!-- FOR DOCTOR  -->
            <div class="container headingConsultation d-none d-lg-block ">
                <h2 class="">Enter your details to come on board RxIx</h2>
            </div>
            <!-- FOR MOBILE  -->
            <div class="container headingConsultation d-block d-lg-none">
                <h2 class="mb-0">Enter your details to </h2>
                <h2>come on board RxIx.</h2>
            </div>
            <div class="container row mt-4">
                <div class="col-sm-12 col-lg-6">
                    <div class="textConsultation ">
                        <p>First Name</p>
                    </div>
                    <div class="form-group input-group">
                    <span class="input-group-addon">Dr.</span>
                    <input type="text" class="form-control cursor" v-model="firstName" autocomplete="off" id="formName" aria-label="firstName" >
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                    <div class="textConsultation">
                        <p>Last Name</p>
                    </div>
                    <input type="text" id="lastName" name="lastName" autocomplete="off" class="form-control cursor"
                        v-model="lastName" />
                </div>
            </div>


            <div class="container row">
                <div class="textConsultation mt-4 ">
                    <p>Select your practice areas</p>
                </div>

                <div class="row">
                    <div class="col-6 col-lg-4  practice-areas" v-for="(checkbox) in practiceAreas" :key="checkbox">
                        <label class="checkbox-inline">
                            <input type="checkbox" class="me-2" name="practiceArea" id="practiceArea"
                                v-model="checkbox.checked" @change="practiceAreaCheckedData">
                            {{ checkbox.practiceArea }}
                        </label>
                    </div>
                </div>
                <div class="textConsultation mt-4 ">
                    <p>Select the services you offer</p>
                </div>
                <div class="row consultations">
                    <div class="col-12 col-sm-6 col-lg-3 offer-consultations" v-for="consultation in clinicConsultation"
                        :key="consultation">
                        <label class="checkbox-inline">
                            <input type="checkbox" name="consultation" class="me-2" id="consultation"
                                v-model="consultation.checked"  @change="consultationCheckedData" />{{ consultation.consultation }}
                        </label>
                    </div>
                </div>
                <!-- <div class="textConsultation mt-4 ">
                <p>Pincode where I mostly practice</p>
            </div> -->
                <div class="row mt-4">
                    <div class="textConsultation ">
                        <p>Pincode</p>
                    </div>
                    <div class="col-sm-12 col-lg-4">
                        <input type="text" id="pincode" name="pincode" maxlength="6" class="form-control cursor" v-model="pincode" />
                    </div>
                </div>
                <div class="text-align text-center">
                    <button class="offer btn btn-blue-color text-white" @click="checkOn()"
                        :disabled="isSubmitDisabled" :class="isSubmitDisabled ? 'disableBtn': ''">Submit</button>
                </div>
            </div>



        </section>

    </div>
    <MobileNavbar></MobileNavbar>
</template>
<script>
import { defineComponent } from "vue";
import MobileNavbar from "../common-mobile-footer.vue"
import axios from "axios";
export default defineComponent({
    components: {
        MobileNavbar
    },
    el: '#hide',
    data() {
        return {
            seen: true,
            home: "",
            // home:false,
            clinic: "",
            tele: "",
            secondOpinion: "",
            clinicHome: true,
            currentPath: "",
            // issubmit: false,
            practiceAreas: [],
            clinicConsultation: [],
            doctorConsutations: {},
            firstName: "",
            lastName: "",
            pincode: "",
            checkedPracticeArea:{},
            checkedConsultation:{},
            practiceId:[]

        };
    },
    created: function () {
        this.currentPath = this.$route.path.split('/')[2]
        this.getPracticeAreas();
        this.getClinicConsultation();
    },
    computed: {
        isSubmitDisabled() {
            const pincodeValidation = /^[1-9](\s*\d){5}$/;
            const nameRegex = /^[A-Za-z.\s]+$/;

            const isNameValid = nameRegex.test(this.firstName) && nameRegex.test(this.lastName);
            const isPincodeValid = pincodeValidation.test(this.pincode);
            const isNoCheckboxChecked = this.practiceAreas.every(checkbox => !checkbox.checked);
            const isNoConsultationChecked = this.clinicConsultation.every(consultation => !consultation.checked);

            return !(isNameValid && isPincodeValid && !isNoCheckboxChecked && !isNoConsultationChecked);
        },

    },
    methods: {
        practiceAreaCheckedData(){
            this.checkedPracticeArea= this.practiceAreas.filter(checkbox => checkbox.checked);
        },
        consultationCheckedData(){
            this.checkedConsultation = this.clinicConsultation.filter(consultation =>consultation.checked);
        },
        getClinicConsultation() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/consultation`)
                .then((response) => {
                    this.clinicConsultation = response.data;
                })
        },
        getPracticeAreas() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/hcp-types?hcpTypes=doctor`)
                .then((response) => {
                    if (response?.data) {
                        response.data.map((data) => {
                            if (data.is_delete === false && data.check === true) {
                                this.practiceAreas.push(data)
                            }
                        })
                    }
                })
        },
        getCase() {
            window.scrollTo(0, 0);
      this.$router.push('/doctor/communities');
        },
        offerConsultation() {
            window.scrollTo(0, 0);
            this.$router.push('/doctor/offerconsultation');
        },
        accessClinical() {
            window.scrollTo(0, 0);
            this.$router.push('/doctor/clinicalresources');
        },
        postConsultation() {
            window.scrollTo(0, 0);
            this.$router.push('/presign/postconsultation');
        },
        joinPatient() {
            window.scrollTo(0, 0);
      this.$router.push('/doctor/patientgroups');
        },
        async checkOn() {
            // this.issubmit = true;
            const payload = {
                firstName: this.firstName,
                lastName: this.lastName,
                pincode: this.pincode,
                practiceId: JSON.stringify(this.checkedPracticeArea),
                consultation: JSON.stringify(this.checkedConsultation),
                routeName: 'receiveconsultation',
                card:'doctor',
                receiveConsultationId:JSON.stringify(2)
            }
            window.scrollTo(0, 0);
            this.$router.push({ path: '/signup', query: payload });
        }
    },
    name: "HomePageB",
    props: {},
});    
</script>
<style>
.receiveConsultationTextHeading h1 {
    font-weight: bold;
}

.banner-sub-content {
    margin-top: 20px;
}

.offer-consultations {
    margin-top: 10px;
    font-size: 18px;
}

.receiveConsultationTextHeading h3 {
    color: #000000;
}

.practice-areas {
    margin-top: 20px;
    font-size: 18px;
}

.form-control.cursor {
    text-align: left;
    border-color: #34989F;
}

.consultations {
    display: flex;
    justify-content: space-between;

}

.text-color.offerText {
    /* color: #00979e; */
    margin-left: 8rem;
    padding-top: 5px;
}

.textConsultation p {
    /* color: #00979e; */
    text-align: left;
    font-size: 22px;
    margin-bottom: 0px;
}

.headingConsultation h2 {
    color: #000;
    text-align: center;
}

.headingConsultation {
    margin-top: 3rem;
}

p.checkbox-inline {
    margin-right: 2rem;
    font-size: 25px;
}

@media screen and (max-width:1366px) {
    p.checkbox-inline {
        width: 18%;
        font-size: 25px;
    }
}

.navigation-list-item-blk {
    transition: 0.3s;
    /* transform: translateX(-100px); */
    transform: translateX(75px);
}

.show-navigation-list {
    transform: translateX(0px);
}

button.presign.btn.btn-blue-color.text-white {
    float: right;
    /* width: auto; */
    width: 40%;
    font-weight: bold;
    font-size: large;
    /* margin: 10px -60px 10px 0px; */
}

.rxix-menu li.nav-item a.nav-link {
    color: #00979e;
}

nav.navbar.navbar-expand-lg.navbar-light {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
}



section.banner-text.receive-banner {
    margin-top: 4.5rem;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/cards-banner7.png");
    background-size: 3500px 593px;
    /* transform: rotate(90deg);  */
    padding: 30px 0px 50px 0px;

}

.banner-sub {
    text-align: center;
    margin-top: 20px;
    color: #000000;
}

button.btn.btn-action {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
}

button.btn.btn-blue-color.text-white {
    width: 100%;
}

button.offer.btn.btn-blue-color.text-white {
    width: auto;
    font-weight: bold;
    font-size: large;
    margin-bottom: 30px;
    margin-top: 26px;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:after {
    content: "";
}
nav.three-nav {
    float: right;
    position: fixed;
    right: 0;
    background: transparent;
}

nav.three-nav:hover {
    transform: perspective(400px) rotateY(1deg);
}

nav.three-nav ul.three-nav {
    text-align: center;
    /* margin-left: -72px; */
    margin-top: 30px
        /* margin-top: 77px; */
}

@media screen and (max-width:992px) {
    nav.three-nav ul.three-nav {
        text-align: center;
        /* margin-left: -72px; */
        margin-top: 0px
            /* margin-top: 77px; */
    }
}

nav.three-nav ul.three-nav li.three-nav {
    position: relative;
    width: 71px;
    height: 96px;
    cursor: pointer;
    background: cadetblue;
    text-transform: uppercase;
    transition: all .4s ease-out;
    list-style: none;
}

nav.three-nav ul.three-nav li:after {
    position: absolute;
    background: white;
    color: crimson;
    top: 0;
    /* right: 70px; */
    left: 0px;
    width: 70px;
    height: 100%;
    opacity: .5;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: 0 100%;
    transition: all .4s ease-out;
}

nav.three-nav ul.three-nav li:nth-child(1):after {
    /* content: "dcba"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(2):after {
    /* content: "get"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(3):after {
    /* content: "offer"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:nth-child(4):after {
    /* content: "access"; */
    line-height: 70px;
}

nav.three-nav ul.three-nav li:nth-child(5):after {
    /* content: "post"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:hover {
    /* transform: translateX(0px); */
    /* transform: translateX(70px); */
    /* transform: rotateY(-360deg); */
    transform: translateX(0px);
    background-color: #fff;
}

nav.three-nav ul.three-nav li:hover:after {
    opacity: 1;
    /* transform: perspective(400px) rotateY(0deg) scale(1); */
    /* transform: perspective(400px) rotateY(180deg) scale(1); */
    transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav ul.three-nav li>div {
    display: inline-block;
    padding: 25px 0;
    background: transparent;
}

nav.three-nav ul.three-nav li.three-nav div {
    position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu {
    list-style: none;
    position: relative;
    left: 35px;
    /* right: 35px; */
    /* top: 200px; */
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0 solid !important;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

@media screen and (max-width:991px) {
    .navbarmobileFix {
        margin-bottom: 7rem;
    }

    section.banner-text.receive-banner {
        margin-top: 0px;
    }

    .offer-consultations {
        margin-top: 20px;
        font-size: 17px;
    }

    .headingConsultation h2 {
        font-size: 28px;
    }

    .textConsultation p {
        font-size: 22px;
    }

    .practice-areas {
        font-size: 17px;
    }

    .receiveConsultationTextHeading h1 {
        font-size: 32px;
    }

    .receiveConsultationText h3 {
        font-size: 22px;
    }

    .headingConsultation {
        margin-top: 2rem;
    }

}

@media screen and (max-width:768px) {

    .form-control.cursor {
        font-size: 2.7vw;
    }
}

@media screen and (max-width: 576px) {
    .practice-areas {
        font-size: 12px;
        margin-top: 10px;
    }

    .offer-consultations {
        font-size: 11px;
        margin-top: 10px;
    }

    .headingConsultation h2 {
        font-size: 21px;
    }

    .headingConsultation {
        margin-top: 1.5rem;
    }

    .textConsultation p {
        font-size: 17px
    }

    .receiveConsultationTextHeading h1 {
        font-size: 25px;
    }

    .receiveConsultationText h3 {
        font-size: 17px;
    }

    section.banner-text.receive-banner {
        padding: 10px 0px 20px 0px;
    }

}


@media screen and (max-width:550px) {
    .form-control.cursor {
        font-size: 3.7vw;
    }
}
</style>